<template>
  <div class="page-container permission-group-page">
    <portal to="page-name">Cadastro de grupo de permissões</portal>
    <div class="card card-page">
      <div class="card-header">
        <h1 class="card-title">Cadastro de grupo de permissões</h1>
      </div>
      <div class="card-body">
        <div class="columns form-group">
          <div class="column col-12 form-group"
               :class="{'has-error': $v.form.name.$error}">
            <label for="name" class="form-label">Nome</label>
            <input
              type="text"
              id="name"
              class="form-input"
              v-model="form.name"
              @change="$v.form.name.$touch()"
              :readonly="!canEdit"
              placeholder="Ex: Recepção"
            >
            <template v-if="$v.form.name.$error">
              <div class="form-input-hint"
                   v-if="!$v.form.name.required">Campo obrigatório</div>
            </template>
          </div>
        </div>
        <div
          v-for="({ module, name, actions }) in actions"
          :key="module"
          class="module"
        >
          <div class="module-title form-group">
            <label class="form-checkbox" v-if="canEdit">
              <input
                type="checkbox"
                @click="toggleModule(module)"
                :checked="isModuleSelected(module)"
                :indeterminate.prop="isModuleSelected(module) === null"
              >
              <i class="form-icon"></i> {{ name }}
            </label>
            <span class="pt-2 pb-2" v-else>{{ name }}</span>
          </div>
          <div class="columns">
            <div v-for="action in actions" :key="action.id" class="column col-6 form-group">
              <div class="form-group" v-if="canEdit">
                <label class="form-checkbox">
                  <input type="checkbox" v-model="action.selected">
                  <i class="form-icon"></i> {{ action.name }}
                </label>
              </div>
              <div class="mb-1" v-else>
                <fa-icon
                  :icon="['far', action.selected ? 'check-circle' : 'ban']"
                  class="text-success"
                  :class="action.selected ? 'text-success' : 'text-warning'"
                ></fa-icon>
                <span class="ml-2">{{ action.name }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <button
          class="btn btn-primary mr-1"
          @click="save()"
          :disabled="saving"
          :class="{loading: saving}"
          v-if="canEdit"
        >Salvar</button>
        <button class="btn" @click="$router.back()">
          Voltar
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/src/validators';
import formMixin from 'src/mixins/form';
import { mergeFrom } from '@/helpers/object';

const modules = {
  clinical: 'Clínico',
};

export default {
  mixins: [formMixin],
  data() {
    return {
      path: '/groups',
      isNew: true,
      actions: [],
      form: this.blankForm(),
      loading: false,
      saving: false,
    };
  },
  async mounted() {
    this.init();
  },
  validations() {
    return {
      form: {
        name: { required },
      },
    };
  },
  computed: {
    canEdit() {
      return /(edit|create)$/.test(this.$route.path);
    },
  },
  methods: {
    init() {
      this.loadActions()
        .then(() => {
          if (this.$route.params.id) {
            this.form.id = this.$route.params.id;
            this.load();
          } else if ('from' in this.$route.query) {
            this.load(this.$route.query.from);
          }
        });
    },
    loadActions() {
      return this.$http.get('/actions')
        .then(({ data }) => {
          this.actions = data.items.reduce((groups, item) => {
            const [module] = item.action.split(':');

            let found = groups.find(group => group.module === module);
            if (!found) {
              found = {
                module,
                name: module in modules ? modules[module] : module,
                actions: [],
                allow: true,
              };
              groups.push(found);
            }

            found.actions.push({
              ...item,
              selected: false,
            });

            return groups;
          }, []);
        })
        .catch(() => {});
    },
    load(copyFrom = null) {
      this.loading = true;

      const isCopy = copyFrom !== null;

      return this.$http
        .get(`${this.path}/${isCopy ? copyFrom : this.form.id}`)
        .then(({ data }) => {
          if (isCopy) {
            data.id = '';
            data.name = `${data.name} (Cópia)`;
          }
          this.form = mergeFrom(this.blankForm(), data);

          this.actions.forEach((module) => {
            module.actions.forEach((action) => {
              action.selected = data.actions.some(({ id }) => id === action.id);
            });
          });
        })
        .catch(() => {})
        .then(() => {
          this.loading = false;
        });
    },
    async save() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return Promise.resolve();
      }

      this.saving = true;

      const formData = this.clone(this.form);
      formData.actions = this.actions.reduce((acc, { actions }) => ([
        ...acc,
        ...actions.filter(action => action.selected)
          .map(({ id, action }) => ({ id, action })),
      ]), []);

      const request = !this.form.id
        ? this.$http.post(this.path, formData)
        : this.$http.put(`${this.path}/${this.form.id}`, formData);

      return request
        .then(({ data }) => {
          if (!this.form.id) {
            this.$router.replace(`/permission-groups/${data.id}/edit`);
            this.form.id = data.id;
          }
          this.$toast.show('Registro salvo');
        })
        .catch(() => {})
        .then(() => {
          this.saving = false;
        });
    },
    getActionModule(key) {
      return this.actions.find(({ module }) => module === key);
    },
    toggleModule(key) {
      const module = this.getActionModule(key);

      const isSelected = this.isModuleSelected(key);

      module.actions.forEach((action) => {
        action.selected = !isSelected;
      });
    },
    isModuleSelected(key) {
      const module = this.getActionModule(key);
      const selectedCount = module.actions.filter(action => action.selected).length;

      if (selectedCount === module.actions.length) {
        return true;
      }
      if (selectedCount === 0) {
        return false;
      }

      return null;
    },
    blankForm() {
      return {
        id: '',
        name: '',
        actions: [],
      };
    },
  },
};
</script>

<style lang="scss">
@import "./src/assets/scss/_variables.scss";

.permission-group-page {
  .module {
    background-color: $gray-color-ultra-light;
    border-radius: $border-radius;
    margin-top: $layout-spacing;
    .module-title {
      background-color: $gray-color-light;
      border-radius: $border-radius $border-radius 0 0;
      font-weight: 600;
      padding: $layout-spacing;
    }
    .columns {
      padding: $layout-spacing;
    }
    svg {
      height: $font-size;
      width: $font-size;
    }
  }
}
</style>
