<template>
  <div class="page-container permission-groups-page">
    <portal to="page-name">Grupo de permissões</portal>
    <div class="card card-page">
      <div class="card-header">
        <h1 class="card-title">Grupos de permissões</h1>
      </div>
      <div class="card-body">
        <div class="columns">
          <div class="column form-group">
            <div class="input-group">
              <input type="text" id="filter-search" name="filter-search" class="form-input"
                     v-model="filter.search" autocomplete="nope">
              <button class="btn btn-neutral btn-action input-group-btn btn-icon" tabindex="-1">
                <fa-icon :icon="['fal', 'search']"/>
              </button>
            </div>
          </div>
          <div class="column col-auto">
            <router-link
              to="/permission-groups/create"
              class="btn btn-primary btn-icon btn-icon-left"
            ><fa-icon :icon="['fal', 'plus']"/> Novo grupo</router-link>
          </div>
        </div>
        <div class="loading mt-2" v-if="loading"></div>
        <template v-else>
          <div class="empty mt-2" v-if="data.items.length === 0">
            <div class="empty-icon">
              <fa-icon :icon="['fal', 'info-circle']" size="3x"/>
            </div>
            <p class="empty-title h5">Grupo de permissão</p>
            <p class="empty-subtitle">
              Nenhum registro foi encontrado.
              Verifique os filtros para realizar uma nova busca.
            </p>
          </div>
          <table class="table table-striped table-hover" v-else>
            <thead>
            <tr>
              <th class="hide-sm" width="40px">#</th>
              <th>Nome</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, i) in data.items" :key="i">
              <td class="hide-sm">{{ i + 1 }}</td>
              <td>
                <router-link
                  :to="editLink(item)"
                  v-if="!item.default"
                >{{ item.name }}</router-link>
                <span v-else>{{ item.name }} <small class="text-gray">(Padrão)</small></span>
              </td>
              <td class="text-right">
                <button
                  class="btn btn-sm btn-action btn-icon btn-gray-outline tooltip mr-1"
                  data-tooltip="Fazer uma cópia"
                  @click="copy(item)"
                ><fa-icon :icon="['fal', 'copy']"></fa-icon></button>
                <button
                  class="btn btn-sm btn-action btn-icon btn-primary tooltip"
                  data-tooltip="Editar"
                  @click="edit(item)"
                  v-if="!item.default"
                ><fa-icon :icon="['fal', 'pencil']"></fa-icon></button>
                <button
                  class="btn btn-sm btn-action btn-icon btn-gray-outline tooltip"
                  data-tooltip="Visualizar"
                  @click="view(item)"
                  v-else
                ><fa-icon :icon="['fal', 'eye']"></fa-icon></button>
              </td>
            </tr>
            </tbody>
          </table>
        </template>
      </div>
      <div class="card-footer">
<!--        <st-pagination :total="data.total"-->
<!--                       :limit="data.limit" @paginate="paginate"></st-pagination>-->
<!--        <span>Total de registros:-->
<!--          <span class="ml-2 text-primary text-bold">{{ data.total }}</span>-->
<!--        </span>-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      filter: {
        search: '',
      },
      data: {
        items: [],
      },
      loading: false,
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.loading = true;

      const params = {
        // limit: this.data.limit,
        // offset: this.data.offset,
      };

      this.$http.get('/groups', { params })
        .then(({ data }) => {
          this.data = data;
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
    edit(item) {
      this.$router.push(this.editLink(item));
    },
    copy(item) {
      this.$router.push(this.copyLink(item));
    },
    view(item) {
      this.$router.push(this.viewLink(item));
    },
    copyLink(item) {
      return `/permission-groups/create?from=${item.id}`;
    },
    editLink(item) {
      return `/permission-groups/${item.id}/edit`;
    },
    viewLink(item) {
      return `/permission-groups/${item.id}`;
    },
  },
};
</script>

<style lang="scss">
@import "./src/assets/scss/_variables.scss";

.permission-groups-page {
}
</style>
